<template>
  <v-app>
    <v-container fluid class="site-width--max" style="padding-left: 0; padding-right: 0">
      <v-row justify="center">
        <v-col style="max-width: 400px">
          <div v-resize="onPageResize" id="siteHeader" ref="siteHeader">
            <div :class="(signedInUser ? 'justify-space-between' : 'justify-center') + ' d-flex align-center pt-3 px-3'" style="width: 100%">
              <router-link :to="{ name: 'home', params: { locale: currentLocale } }" style="text-decoration: none">
                <div v-if="$route.name === 'home'" class="homepage--logo">
                  <img src="@/assets/images/logo.png" height="40" alt="SmartTransyt" />
                  <div><em><strong>AGENT PORTAL</strong></em></div>
                </div>
                <img v-if="$route.name !== 'home'" src="@/assets/images/logo-badge.png" height="40" alt="SmartTransyt" />
              </router-link>
              <div v-if="$route.name !== 'home'" class="d-flex justify-center align-center mx-3">
                <v-icon class="mr-2" v-if="$router.currentRoute.meta.icon">{{$router.currentRoute.meta.icon}}</v-icon>
                <h3>{{$router.currentRoute.meta.title}}</h3>
              </div>
              <div style="height: 47px">
                <v-menu
                  v-if="signedInUser"
                  class="ml-2"
                  transition="slide-y-transition"
                  offset-y
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon x-large color="accent">fa fa-user-circle</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="(item, i) in menuItems"
                      @click="item.onClick"
                      :key="i"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col id="appContent">
          <v-main>
            <router-view
              :key="rootViewKey"
              :passenger-authorization-token="passengerAuthorizationToken"
              :signed-in-user="signedInUser"
              :page-height-offset="pageHeightOffset"
            />
          </v-main>
        </v-col>
      </v-row>
    </v-container>
    <site-alerts-tray style="width: 100%" />
  </v-app>
</template>

<script>
import src from './src'
export default src
</script>
