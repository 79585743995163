/**
 * Allows to async/await until a specific condition is true in a custom function passed in to "untilFunction" OR the timeoutMsecs has elapsed
 * @param {function({cancelled:boolean,remainingAttempts:number|undefined}):Promise<true|false>} untilFunction - If cancelled === true, you can choose to abort within your function
 * @param {object} options
 * @param {number} [options.timeoutMsecs] - If elapsed the while loop waiting for a result will end regardless of the "untilFunction" result - only one of timeoutMsecs or attempts params will be used
 * @param {number} [options.attempts] - Provide a specific number of attempts to try "untilFunction" is truthy - only one of timeoutMsecs or attempts params will be used
 * @returns {Promise<void>}
 */
export async function waitUntil (untilFunction, { timeoutMsecs, attempts } = {}) {
  if (!timeoutMsecs && !attempts) {
    timeoutMsecs = 30000
  }

  let remainingAttempts = null

  if (attempts) {
    remainingAttempts = attempts
  }

  const whileStatus = { cancelled: false, remainingAttempts }
  let timeoutHandle = null

  try {
    let untilFunctionResult = null

    if (timeoutMsecs) {
      timeoutHandle = setTimeout(() => {
        whileStatus.cancelled = true
      }, timeoutMsecs)
    }

    do {
      untilFunctionResult = await untilFunction(whileStatus)
      whileStatus.cancelled = Boolean(untilFunctionResult)

      if (attempts && !whileStatus.cancelled) {
        remainingAttempts--
        whileStatus.cancelled = Boolean(!remainingAttempts)
        whileStatus.remainingAttempts = remainingAttempts
      }
    } while (!whileStatus.cancelled)

    clearTimeout(timeoutHandle)
    return untilFunctionResult
  } catch (error) {
    clearTimeout(timeoutHandle)
    throw error
  }
}
