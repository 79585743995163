import Vue from 'vue'
import moment from 'moment-timezone'
import { formatDate } from '@smarttransit/common'

export default {
  name: 'st-calendar',
  props: {
    textFieldProps: Object,
    datePickerProps: Object,
    disabled: Boolean,
    minDate: Date,
    maxDate: Date,
    dateRange: Boolean,
    closeOnContentClick: Boolean,
    value: [Date, String, Number, Object] // if dateRange===true, then value becomes {fromDate: Date, toDate: Date}
  },
  data () {
    return {
      calendarDate: null,
      dateMenu: false,
      fromDateMenu: false,
      toDateMenu: false,
      isEditable: true,
      parsedCloseOnContentClick: true
    }
  },
  mounted () {
    if (this.$props.value) {
      this.setValueToCalendarDate(this.$props.value)
    }

    if (typeof this.$props.closeOnContentClick === 'boolean') {
      this.parsedCloseOnContentClick = this.$props.closeOnContentClick
    }

    this.isEditable = !this.$props.editable
  },
  computed: {
    computedDateFormatted () {
      return this.calendarDate ? formatDate(this.calendarDate) : ''
    },
    computedDateFromFormatted () {
      return this.calendarDate && this.calendarDate.fromDate ? formatDate(this.calendarDate.fromDate) : ''
    },
    computedDateToFormatted () {
      return this.calendarDate && this.calendarDate.toDate ? formatDate(this.calendarDate.toDate) : ''
    },
    computedDateMin () {
      if (this.$props.minDate) {
        return moment(this.$props.minDate).format('Y-MM-DD')
      }
    },
    computedDateMax () {
      if (this.$props.maxDate) {
        return moment(this.$props.maxDate).format('Y-MM-DD')
      }
    }
  },
  watch: {
    calendarDate: function (newVal) {
      if (this.$props.dateRange) {
        this.$emit('input', {
          fromDate: newVal.fromDate ? new Date(newVal.fromDate) : null,
          toDate: newVal.toDate ? new Date(newVal.toDate) : null
        })
      } else {
        this.$emit('input', new Date(newVal))
      }
    },
    value (val) {
      this.setValueToCalendarDate(val)
    },
    closeOnContentClick (val) {
      this.parsedCloseOnContentClick = val
    },
    disabled (val) {
      this.isEditable = !val
    }
  },
  methods: {
    setValueToCalendarDate (val) {
      if (this.$props.dateRange) {
        if (!this.calendarDate) {
          this.calendarDate = {}
        }

        this.calendarDate.fromDate = val && val.fromDate ? val.fromDate.toISOString() : null
        this.calendarDate.toDate = val && val.toDate ? val.toDate.toISOString() : null
      } else {
        this.calendarDate = val ? val.toISOString() : null
      }
    }
  }
}
