import '@babel/polyfill'
import Vue from 'vue'
import './plugins/qrcode-reader'
import './plugins/uploader'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import components from './components/'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

for (const key in components) {
  Vue.component(key, components[key])
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
