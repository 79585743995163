<template>
  <v-menu style="width: 100%">
    <template v-slot:activator="{ on }">
      <div class="mb-5 d-flex flex-row justify-start align-center">
        <img v-if="mobileMoneyProvider" alt="selected logo" class="mr-2" height="50px"
             :src="mobileMoneyProvider.metadata.src"/>
        <v-btn text light v-on="on" style="padding-left: 0">
          <span v-if="mobileMoneyProvider">{{ mobileMoneyProvider.text }}</span>
          <span v-if="!mobileMoneyProvider">{{currentPlaceholder}}</span>
          <v-icon class="ml-2" v-html="'fa fa-caret-down'"></v-icon>
        </v-btn>
        <v-btn v-if="mobileMoneyProvider" icon class="ml-2" @click="clear()"><v-icon>fas fa-times-circle</v-icon></v-btn>
      </div>
    </template>
    <v-list v-if="moneyAccountTypes">
      <v-list-item
        v-for="(item, index) in moneyAccountTypes"
        :key="index"
        @click="mobileMoneyProvider = item"
      >
        <v-list-item-title class="mb-5 d-flex flex-row justify-space-between align-center">
          <img class="mr-2" height="50px" alt="logo" :src="item.metadata.src"/>
          <div>{{ item.text }}</div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import src from './src'
export default src
</script>
