<template>
  <div>
    <slot v-bind:open-modal="openModal">
      <v-btn @click="openModal">Manage Mobile Money Accounts</v-btn>
    </slot>
    <v-dialog v-model="mobileMoneyAccountDialog" max-width="500">
      <v-card>
        <v-card-title class="lighten-2 grey justify-center align-center dialog-close-container">
          <h3>Manage mobile money accounts</h3>
          <v-btn class="dialog-close" text icon @click="closeModal"><v-icon>far fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="currentMobileMoneyAccount">
            <div class="mb-3">
              <phone-number-field :child-props="{ label: 'Mobile money number' }" :default-prefix="defaultPrefix" v-model="currentMobileMoneyAccount.phoneNumber" />
            </div>
            <div class="mb-3">
              <div>Mobile money provider</div>
              <div>
                <mobile-money-account-selector v-model="currentMobileMoneyAccount.moneyAccountTypeId" placeholder="Select Mobile Money Provider" />
              </div>
            </div>
          </div>
          <div v-if="!currentMobileMoneyAccount" class="mb-3">
            <v-btn block @click="createMobileMoneyAccountTemplate()">
              <v-icon left>far fa-plus</v-icon> Register Mobile Money Account
            </v-btn>
          </div>
          <div v-if="!currentMobileMoneyAccount" style="max-height: 400px; overflow-y: auto">
            <v-list two-line>
              <template v-for="(item, index) in agentMobileMoneyAccounts">
                <v-list-item
                  :key="item.accountId"
                >
                  <v-list-item-avatar>
                    <v-img :src="item.moneyAccountTypeImageSrc" alt="" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="item.phoneNumberLabel"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.moneyAccountTypeLabel"></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-btn fab small v-if="!isDeletingAccount" @click="deleteCurrentMobileMoneyAccount(item)">
                      <v-icon color="red" small>fas fa-trash</v-icon>
                    </v-btn>
                    <v-progress-circular v-if="isDeletingAccount" indeterminate :size="50" :width="6" color="primary" />
                  </v-list-item-icon>
                </v-list-item>

                <v-divider
                  v-if="index < agentMobileMoneyAccounts.length - 1"
                  :key="index"
                  inset
                />
              </template>
            </v-list>
          </div>
        </v-card-text>
        <v-card-actions v-if="currentMobileMoneyAccount">
          <v-btn text color="warning" :disabled="isRegisteringAccount" @click="currentMobileMoneyAccount = null">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="isRegisteringAccount"
            :loading="isRegisteringAccount"
            @click="registerCurrentMobileMoneyAccount()">
            Register
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.dialog-close-container {
  position: relative;
  margin-bottom: 20px;

  .dialog-close {
    position: absolute;
    right: 0;
    top: 20%;
  }
}
</style>

<script>
import src from './src'
export default src
</script>
