import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function requestPassengerVerification ({ agentProfileId, phoneNumber, alternateId }) {
  return apiRequests.post('/v1/agent-profile/' + agentProfileId + '/request-passenger-verification', { phoneNumber, alternateId }, {
    headers: { Authorization: store.getters.tokenId }
  }).then((result) => {
    return result.data
  })
}

function confirmPassengerVerification ({ agentProfileId, phoneNumber, alternateId, code }) {
  return apiRequests.post('/v1/agent-profile/' + agentProfileId + '/confirm-passenger-verification', { phoneNumber, alternateId, code }, {
    headers: { Authorization: store.getters.tokenId }
  }).then((result) => {
    return result.data
  })
}

/**
 * @param {object} options
 * @param {string} options.agentProfileId
 * @param {string} options.phoneNumber
 * @param {string} options.mobileMoneyAccountType
 * @returns {Promise<{imei:string}>}
 */
function registerPassenger ({ agentProfileId, phoneNumber, mobileMoneyAccountType }) {
  return apiRequests.post('/v1/agent-profile/' + agentProfileId + '/passenger-registration/', { phoneNumber, mobileMoneyAccountType }, {
    headers: {
      Authorization: store.getters.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

/**
 * @param {object} options
 * @param {string} options.agentProfileId
 * @param {string} options.imei
 * @param {string} options.phoneNumber
 * @param code
 * @returns {Promise<{agentProfileId:string,phoneNumber:string,tokenId:string,ttl:number,dateCreated:string,dateUpdated:string}>}
 */
function verifyPassengerRegistration ({ agentProfileId, imei, phoneNumber, code }) {
  return apiRequests.put('/v1/agent-profile/' + agentProfileId + '/passenger-registration/verification/', { imei, phoneNumber, code }, {
    headers: {
      Authorization: store.getters.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

function getPassengerIdentifier ({ agentProfileId, phoneNumber, alternateId }) {
  return apiRequests.get('/v1/agent-profile/' + agentProfileId + '/passenger-identifier/', {
    params: { phoneNumber, alternateId },
    headers: {
      Authorization: store.getters.tokenId,
      'x-agent-authorization': store.getters.passengerAuthorizationToken && store.getters.passengerAuthorizationToken.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

/**
 * @param {object} options
 * @param {string} options.agentProfileId
 * @param {string} [options.phoneNumber] - One of phoneNumber or alternateId is required
 * @param {string} [options.alternateId] - E.g. Beacon ID from QRCode, One of phoneNumber or alternateId is required
 * @returns {Promise<{phoneNumber:string,dateCreated:string,dateUpdated:string,alternateIdentifiers:string[]}>}
 */
function getPassengerStatus ({ agentProfileId, phoneNumber, alternateId }) {
  return apiRequests.get('/v1/agent-profile/' + agentProfileId + '/passenger-status/', {
    params: { phoneNumber, alternateId },
    headers: {
      Authorization: store.getters.tokenId
    }
  }).then((result) => {
    return result?.data
  })
}

function completePassengerRegistration ({ agentProfileId, passengerIdentifierId, recoveryContact, countryCode }) {
  return apiRequests.put('/v1/agent-profile/' + agentProfileId + '/passenger-registration/completion/', { passengerIdentifierId, recoveryContact, countryCode }, {
    headers: {
      Authorization: store.getters.tokenId,
      'x-agent-authorization': store.getters.passengerAuthorizationToken && store.getters.passengerAuthorizationToken.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

function completePassengerRegistrationAndReplace ({ agentProfileId, passengerIdentifierId, recoveryContact, countryCode }) {
  return apiRequests.post('/v1/agent-profile/' + agentProfileId + '/passenger-registration/replace/', { passengerIdentifierId, recoveryContact, countryCode }, {
    headers: {
      Authorization: store.getters.tokenId,
      'x-agent-authorization': store.getters.passengerAuthorizationToken && store.getters.passengerAuthorizationToken.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

/**
 * @typedef {id:string, identifierKey:string, passengerProfileId:string, identifierType:string, dateCreated:string, dateUpdated:string, dateArchived:string} PassengerAlternateIdentifier
 */

/**
 * @param {object} options
 * @param {string} options.agentProfileId
 * @param {string} options.passengerProfileId
 * @param {string} options.macAddress
 * @param {object} [options.metadata]
 * @returns {Promise<PassengerAlternateIdentifier>}
 */
function registerPassengerAlternateIdentifier ({ agentProfileId, passengerProfileId, macAddress, metadata }) {
  return apiRequests.post('/v1/agent-profile/' + agentProfileId + '/passenger-alternate-identifier/ble/', {
    passengerProfileId,
    macAddress,
    metadata: { registeredVia: 'agentPortal', ...metadata }
  }, {
    headers: {
      Authorization: store.getters.tokenId,
      'x-agent-authorization': store.getters.passengerAuthorizationToken && store.getters.passengerAuthorizationToken.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

/**
 * @param {object} options
 * @param {string} options.agentProfileId
 * @param {string} options.passengerProfileId
 * @param {string} options.passengerAlternateIdentifierId
 * @returns {Promise<PassengerAlternateIdentifier>}
 */
function unregisterPassengerAlternateIdentifier ({ agentProfileId, passengerProfileId, passengerAlternateIdentifierId }) {
  return apiRequests.delete('/v1/agent-profile/' + agentProfileId + '/passenger-alternate-identifier/ble/', {
    data: { passengerProfileId, passengerAlternateIdentifierId },
    headers: {
      Authorization: store.getters.tokenId,
      'x-agent-authorization': store.getters.passengerAuthorizationToken && store.getters.passengerAuthorizationToken.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

function getPassengerAlternateIdentifiers ({ agentProfileId, passengerProfileId }) {
  return apiRequests.get('/v1/agent-profile/' + agentProfileId + '/passenger-alternate-identifier/', {
    params: { passengerProfileId },
    headers: {
      Authorization: store.getters.tokenId,
      'x-agent-authorization': store.getters.passengerAuthorizationToken && store.getters.passengerAuthorizationToken.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

function getPassengerPhotoUrl ({ agentProfileId, passengerProfileId }) {
  return apiRequests.get(`/v1/agent-profile/${agentProfileId}/passenger-profile/${passengerProfileId}/photo/secure-url/`, {
    headers: {
      Authorization: store.getters.tokenId,
      'x-agent-authorization': store.getters?.passengerAuthorizationToken?.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

function savePassengerPhoto ({
  agentProfileId,
  passengerProfileId,
  photoFile
}) {
  const bodyFormData = new FormData()

  if (photoFile) {
    bodyFormData.append('photo', photoFile)
  }

  return apiRequests.put(`/v1/agent-profile/${agentProfileId}/passenger-profile/${passengerProfileId}/photo/`, bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: store.getters.tokenId,
      'x-agent-authorization': store.getters?.passengerAuthorizationToken?.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

function deletePassengerPhoto ({ agentProfileId, passengerProfileId }) {
  return apiRequests.delete(`/v1/agent-profile/${agentProfileId}/passenger-profile/${passengerProfileId}/photo/`, {
    headers: {
      Authorization: store.getters.tokenId,
      'x-agent-authorization': store.getters?.passengerAuthorizationToken?.tokenId
    }
  }).then((result) => {
    return result.data
  })
}

export {
  requestPassengerVerification,
  confirmPassengerVerification,
  registerPassenger,
  verifyPassengerRegistration,
  getPassengerIdentifier,
  completePassengerRegistration,
  completePassengerRegistrationAndReplace,
  registerPassengerAlternateIdentifier,
  unregisterPassengerAlternateIdentifier,
  getPassengerAlternateIdentifiers,
  getPassengerPhotoUrl,
  savePassengerPhoto,
  deletePassengerPhoto,
  getPassengerStatus
}
