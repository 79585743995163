<template>
  <v-snackbar
    :value="$store.getters.siteAlerts.length && isAtLeastOneVisible"
    bottom
    multi-line
    centered
    tile
    width="100%"
    :dark="false"
    :timeout="-1"
  >
<!--  <v-bottom-sheet :value="$store.getters.siteAlerts.length && isAtLeastOneVisible" @click:outside="removeAllAlerts" hide-overlay>-->
    <div class="d-flex justify-center" style="position: relative;">
      <v-btn class="site-alerts-close" icon @click="removeAllAlerts()">
        <v-icon>fal fa-times</v-icon>
      </v-btn>
      <div class="site-alerts-messages">
        <div class="site-width--max">
          <div v-for="alert in $store.getters.siteAlerts" :key="`key${alert.id}`" v-show="alert.visible">
            <div><small><v-icon small :class="`site-alerts-messages__${alert.type}`" v-html="getAlertIcon(alert)"></v-icon> &nbsp; {{alert.date}}</small></div>
            <div class="mb-2 mr-3" v-html="alert.message"></div>
          </div>
        </div>
      </div>
    </div>
  </v-snackbar>
<!--  </v-bottom-sheet>-->
</template>

<style lang="scss" scoped>
  .site-alerts-messages {
    max-height: 70%;
    overflow-y: auto;
    margin: 30px;
    .site-alerts-messages__success {
      color: #4CAF50
    }
    .site-alerts-messages__info {
      color: #2196F3
    }
    .site-alerts-messages__warning {
      color: #FFC107
    }
    .site-alerts-messages__error {
      color: #f65122
    }
  }

  .site-alerts-close {
    position: absolute;
    right: 15px;
    top: 15px;
  }
</style>

<script>
import src from './src'
export default src
</script>
