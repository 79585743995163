<template>
  <div>
    <qrcode-stream v-if="!detectedQrCode" :track="paintScannerBoundingBox" @init="onScannerLoaded" @decode="onScannerDecode">
      <div v-if="scannerLoading" class="layout justify-center align-center" style="width: 100%; height: 100%;">
        <v-progress-circular indeterminate :size="80" :width="6" color="primary" />
      </div>
    </qrcode-stream>
    <v-alert v-if="!detectedQrCode && displayScannerFeedback" dense :value="true" type="info" class="my-2">
      {{scannerFeedback}}
    </v-alert>
    <v-alert v-if="!detectedQrCode" dense :value="Boolean(scannerErrorFeedback)" type="error">
      {{scannerErrorFeedback}}
    </v-alert>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
