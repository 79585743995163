import Vue from 'vue'
import Router from 'vue-router'
import _ from 'lodash'
import store from './store'
import { isAuthorized, addAlert } from './utilities/helpers'

Vue.use(Router)

const siteRouter = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'root',
      props: true,
      redirect: { name: 'home', params: { locale: process.env.VUE_APP_DEFAULT_LOCALE } }
    },
    {
      path: '/:locale?/',
      props: true,
      name: 'home',
      component: () => import('./pages/home/template'),
      children: [
        {
          path: 'signout/',
          name: 'signout',
          props: true,
          component: () => import('./pages/sign-out/template'),
          beforeEnter: (to, from, next) => {
            const token = store.getters.token
            if (token && token.expires > Date.now()) {
              if (!confirm('Confirm signing out')) {
                return next(false)
              }
            }
            next()
          }
        },
        {
          path: 'confirm/',
          name: 'confirm',
          props: true,
          component: () => import('./pages/confirm/template'),
          meta: { title: 'Confirmation' }
        },
        {
          path: 'passenger-status/',
          props: true,
          name: 'passenger-status',
          component: () => import('./pages/passenger-status/template'),
          meta: { requiresAuth: 'agent', title: 'Passenger Status', icon: 'fa fa-monitor-heart-rate' }
        },
        {
          path: 'passenger-registration/',
          props: true,
          name: 'passenger-registration',
          component: () => import('./pages/passenger-registration/template'),
          meta: { requiresAuth: 'agent', title: 'Passenger Registration', icon: 'fa fa-user-plus' }
        },
        {
          path: 'passenger-tag-registration/',
          props: true,
          name: 'passenger-tag-registration',
          component: () => import('./pages/passenger-tag-registration/template'),
          meta: { requiresAuth: 'agent', title: 'Beacon/Tag Registration', icon: 'fa fa-signal-stream' }
        },
        {
          path: 'passenger-credit/',
          props: true,
          name: 'passenger-credit',
          component: () => import('./pages/passenger-credit/template'),
          meta: { requiresAuth: 'agent', title: 'Passenger Credit', icon: 'fa fa-paper-plane' }
        },
        {
          path: 'passenger-photo/',
          props: true,
          name: 'passenger-photo',
          component: () => import('./pages/passenger-photo/template'),
          meta: { requiresAuth: 'agent', title: 'Passenger Photo', icon: 'fa fa-camera' }
        },
        {
          path: 'one-time-ticket/',
          props: true,
          name: 'passenger-one-time-ticket',
          component: () => import('./pages/one-time-ticket/template'),
          meta: { requiresAuth: 'agentstaff', title: 'One Time Ticket', icon: 'fa fa-exclamation-circle' }
        },
        {
          path: 'profile/',
          props: true,
          name: 'profile',
          component: () => import('./pages/profile/template'),
          meta: { requiresAuth: 'agent', title: 'My Profile', icon: 'fa fa-user-edit' }
        },
        {
          path: 'transactions/',
          props: true,
          name: 'transactions',
          component: () => import('./pages/transactions/template'),
          meta: { requiresAuth: 'agent', title: 'Your Transactions', icon: 'fa fa-book' }
        }
      ]
    }
  ]
})

function _performAuthCheck (user, to, next) {
  let isPermitted
  let requiresAuth = to.meta.requiresAuth
  if (!requiresAuth && to.matched && to.matched.length) {
    for (let i = to.matched.length - 1; i >= 0; i--) {
      if (to.matched[i].meta.requiresAuth) {
        requiresAuth = to.matched[i].meta.requiresAuth
        break
      }
    }
  }
  if (user) {
    if (_.isArray(requiresAuth)) {
      for (let i = 0; i < requiresAuth.length; i++) {
        isPermitted = isAuthorized(user, requiresAuth[i])

        if (isPermitted) {
          break
        }
      }
    } else {
      isPermitted = isAuthorized(user, requiresAuth)
    }
  } else {
    isPermitted = !requiresAuth || !requiresAuth.length
  }

  if (!isPermitted) {
    addAlert({
      message: `Not authorized to view the page: ${to.meta.title}.${user ? '' : ' You may need to <a href="/?forward=' + to.path + '" class="warn warning">sign in</a> again.'}`,
      type: 'error'
    })

    next(false)
    return false
  } else {
    next()
    return true
  }
}

let userPromiseHandle

siteRouter.beforeEach((to, from, next) => {
  const parentRequiresAuth = to.matched.some(m => m.meta.requiresAuth)

  if (to.meta.requiresAuth || parentRequiresAuth) {
    const user = _.get(store, 'state.credentials.user')
    const token = store.getters.token

    if (!token || token.expires <= Date.now()) {
      console.log('no token')
      if (!_performAuthCheck(null, to, next)) {
        if (to.name === 'home') {
          siteRouter.push({ name: 'home' })
        } else {
          siteRouter.push({ name: 'home', query: { forward: to.fullPath } })
        }
      }
    } else {
      if (user) {
        console.log('checking sequentially')
        _performAuthCheck(user, to, next)
      } else {
        console.log('checking async')
        const userPromise = store.state.credentials.userPromise
        if (userPromise) {
          userPromise.finally(() => {
            console.log('promise complete')
            const user = _.get(store, 'state.credentials.user')
            _performAuthCheck(user, to, next)
          })
        } else {
          if (userPromiseHandle) {
            clearTimeout(userPromiseHandle)
            userPromiseHandle = null
          }
          console.log('no async, setting timeout')
          userPromiseHandle = setTimeout(() => {
            const userPromise = store.state.credentials.userPromise
            if (userPromise) {
              userPromise.finally(() => {
                const user = _.get(store, 'state.credentials.user')
                _performAuthCheck(user, to, next)
              })
            } else {
              const user = _.get(store, 'state.credentials.user')
              if (user) {
                _performAuthCheck(user, to, next)
              } else {
                next(false)
              }
            }
          }, 2000)
        }
      }
    }
  } else {
    next()
  }
})

export default siteRouter
