import { findCountryCurrencies } from '../services'

export default {
  state: {
    phoneNumberCountryCodes: [
      { code: '+233', title: 'Ghana', countryId: 'GHA' },
      { code: '+1', title: 'North America (Canada, US)', countryId: 'GHA' },
      { code: '+353', title: 'Ireland', countryId: 'GHA' },
      { code: '+44', title: 'United Kingdom', countryId: 'GHA' }
    ]
  },
  getters: {
    agencies: (state) => state.agencies,
    getCountryCurrencies: async (state) => {
      if (!state.countryCurrencies) {
        const countryCurrencies = await findCountryCurrencies()
        if (countryCurrencies && countryCurrencies.length) {
          state.countryCurrencies = {}
          countryCurrencies.forEach((currency) => {
            state.countryCurrencies[currency.countryId] = currency
          })
        }
      }
      return state.countryCurrencies
    },
    phoneNumberCountryCodes: (state) => state.phoneNumberCountryCodes
  }
}
