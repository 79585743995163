import { addAlert } from '../../utilities/helpers'
import { confirmPassengerVerification, requestPassengerVerification } from '../../services'
import { C_SET_PASSENGER_TOKEN } from '../../utilities/mutation-types'

export default {
  props: {
    childProps: Object,
    defaultPrefix: String,
    value: Object,
    initialVerificationType: Number,
    agentProfileId: String,
    onPassengerVerified: Function,
    onDetectedQrCodeChanged: Function,
    onPassengerMobileChanged: Function,
    hideVerificationOptions: Boolean,
    disableVerificationOptions: Boolean
  },
  data () {
    return {
      accessedPassenger: null,
      verificationType: 0, // 0 = "phone", 1 = "alternate id"
      passengerMobile: '',
      verificationCode: '',
      detectedQrCode: '',
      isVerifying: false,
      isSendingVerificationCode: false
    }
  },
  mounted () {
    if (typeof this.$props.initialVerificationType === 'number') {
      this.verificationType = this.$props.initialVerificationType
    }
  },
  watch: {
    accessedPassenger (val) {
      this.$emit('input', val)
    },
    passengerMobile (val) {
      if (typeof this.$props.onPassengerMobileChanged === 'function') {
        this.$props.onPassengerMobileChanged(val)
      }
    },
    detectedQrCode (val) {
      if (val) {
        if (!this.$props.disableVerificationOptions) {
          this.sendVerificationCode()
        }

        if (typeof this.$props.onDetectedQrCodeChanged === 'function') {
          this.$props.onDetectedQrCodeChanged(val)
        }
      }
    }
  },
  methods: {
    filterQrCode (qrcodeData) {
      if (qrcodeData && qrcodeData.replace(/:/ug, '').length === 12 && /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{8}/.test(qrcodeData.replace(/:/ug, ''))) {
        return qrcodeData.replace(/:/ug, '')
      }

      return ''
    },
    async sendVerificationCode () {
      try {
        this.isSendingVerificationCode = true

        await requestPassengerVerification({
          agentProfileId: this.$props.agentProfileId,
          phoneNumber: this.verificationType === 0 ? this.passengerMobile : undefined,
          alternateId: this.verificationType === 1 ? this.detectedQrCode : undefined
        })

        addAlert({ message: 'Verification request successfully sent, please ask passenger for the text sent to their mobile device', type: 'success' })
      } catch (error) {
        addAlert({ message: error, type: 'error' })
      } finally {
        this.isSendingVerificationCode = false
      }
    },
    async confirmVerification () {
      try {
        this.isVerifying = true

        const result = await confirmPassengerVerification({
          agentProfileId: this.$props.agentProfileId,
          phoneNumber: this.verificationType === 0 ? this.passengerMobile : undefined,
          alternateId: this.verificationType === 1 ? this.detectedQrCode : undefined,
          code: this.verificationCode
        })

        this.$store.commit(C_SET_PASSENGER_TOKEN, { newPassengerToken: result })

        if (typeof this.$props.onPassengerVerified === 'function') {
          this.$props.onPassengerVerified(result)
        }
      } catch (error) {
        addAlert({ message: error, type: 'error' })
      } finally {
        this.isVerifying = false
      }
    },
    resetQrcodeScan () {
      this.detectedQrCode = ''
    }
  }
}
