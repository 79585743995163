<template>
  <div>
    <v-text-field
      @change="onCurrencyChange"
      v-model="processedValue"
      v-bind="childProps"
      type="number" />
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
