import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function getAgentMobileMoneyAccounts ({ agentProfileId }) {
  return apiRequests.get(`/v1/agent-profile/${agentProfileId}/agent-mobile-money-accounts`, {
    headers: { Authorization: store.getters.tokenId }
  }).then((result) => {
    return result.data
  })
}

function registerAgentMobileMoneyAccount ({ agentProfileId, phoneNumber, moneyAccountTypeId }) {
  return apiRequests.post(`/v1/agent-profile/${agentProfileId}/agent-mobile-money-account`, {
    phoneNumber,
    moneyAccountTypeId
  }, {
    headers: { Authorization: store.getters.tokenId }
  }).then((result) => {
    return result.data
  })
}

function purchaseAgentCredit ({ agentMobileMoneyAccountId, amount }) {
  return apiRequests.post(`/v1/agent-mobile-money-account/${agentMobileMoneyAccountId}/debit-request/`, {
    amount
  }, {
    headers: { Authorization: store.getters.tokenId }
  }).then((result) => {
    return result.data
  })
}

function refundAgentCredit ({ agentMobileMoneyAccountId, amount }) {
  return apiRequests.post(`/v1/agent-mobile-money-account/${agentMobileMoneyAccountId}/refund-request/`, {
    amount
  }, {
    headers: { Authorization: store.getters.tokenId }
  }).then((result) => {
    return result.data
  })
}

function removeAgentMobileMoneyAccount (agentMobileMoneyAccountId) {
  return apiRequests.delete(`/v1/agent-mobile-money-account/${agentMobileMoneyAccountId}/`, {
    headers: { Authorization: store.getters.tokenId }
  }).then((result) => {
    return result.data
  })
}

async function checkAgentTransactionLogStatus (transactionId) {
  const transaction = await apiRequests.get(`/v1/agent-transaction-log/${transactionId}/`, {
    headers: { Authorization: store.getters.tokenId }
  }).then((result) => {
    return result.data
  })

  switch (transaction.logStatus) {
    case 'pending':
    case 'processing':
      return { pending: true }
    case 'completed':
      return { completed: true }
    case 'rejected':
      throw new Error('Mobile money transaction was rejected')
    case 'cancelled':
      throw new Error('Mobile money transaction was cancelled')
    case 'error':
      throw new Error('Mobile money transaction encountered an error')
    case 'timedout':
      throw new Error('Mobile money transaction timed out')
    default:
      throw new Error('An unknown error occurred')
  }
}

function getMoneyAccountTypes (countryId) {
  return apiRequests.get('/v1/money-account-type/', {
    params: { filter: { countryId } },
    headers: { Authorization: store.getters.tokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getAgentMobileMoneyAccounts,
  registerAgentMobileMoneyAccount,
  removeAgentMobileMoneyAccount,
  purchaseAgentCredit,
  refundAgentCredit,
  checkAgentTransactionLogStatus,
  getMoneyAccountTypes
}
