const _MIME_TYPE_GROUPS = {
  ALL_DOCS: ['*/*'],
  IMAGES: ['image/png', 'image/gif', 'image/jpeg', 'image/webp', 'application/pdf'],
  VIDEO: ['video/x-msvideo', 'video/mp4', 'video/mpeg', 'video/ogg', 'video/webm'],
  AUDIO: ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/webm'],
  TEXT: ['text/plain'],
  DOCS: ['application/pdf', 'application/msword', 'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/rtf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.presentation', 'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text']
}

export const MIME_TYPE_GROUPS = _MIME_TYPE_GROUPS

export default {
  methods: {
    isImage (type) {
      return String(type).indexOf('image/') > -1
    },
    getDocumentIcon (type) {
      if (this.isImage(type)) {
        return 'far fa-file-image'
      } else if (type === 'application/pdf') {
        return 'far fa-file-pdf'
      } else if (MIME_TYPE_GROUPS.AUDIO.includes(type)) {
        return 'far fa-file-audio'
      } else if (MIME_TYPE_GROUPS.VIDEO.includes(type)) {
        return 'far fa-file-video'
      } else if (['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(type)) {
        return 'far fa-file-word'
      } else if (['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(type)) {
        return 'far fa-file-excel'
      } else if (['application/vnd.ms-powerpoint'].includes(type)) {
        return 'far fa-file-powerpoint'
      } else {
        return 'far fa-file-alt'
      }
    }
  }
}
