import Vue from 'vue'
import Vuex from 'vuex'
import siteAlerts from './store-modules/site-alerts'
import cacheSettings from './store-modules/cache-settings'
import siteProperties from './store-modules/site-properties'
import credentials from './store-modules/credentials'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    siteAlerts,
    cacheSettings,
    siteProperties,
    credentials
  }
})
