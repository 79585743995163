import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function renewToken (token) {
  return apiRequests.put('/v1/st-user/token/renew/', { token: token || store.getters.tokenId }, {
    headers: { Authorization: store.getters.tokenId }
  }).then((result) => {
    return result.data
    // store.commit(C_RENEW_TOKEN, { newAccessToken: newAccessToken.data })
  })
}

function signOut () {
  return apiRequests.post('/v1/st-user/logout/', null, {
    headers: { Authorization: store.getters.tokenId }
  })
  // commit(C_INVALIDATE_TOKEN)
  // commit(C_SET_SIGNED_IN_USER, { newUser: null })
}

function signIn ({ email, phoneNumber, password }) {
  return apiRequests.post('/v1/agent-profile/login-token/', {
    email,
    phoneNumber,
    password
  }).then((result) => {
    return result.data
  })
}

function getSignedInUser () {
  const user = store.state && store.state.credentials && store.state.credentials.user

  if (!user) {
    const token = store.getters.token

    if (token) {
      return apiRequests.get(`/v1/st-user/${token.userId}/`, {
        headers: { Authorization: token.id },
        params: { filter: { include: ['roles', 'agentProfile'] } }
      }).then((result) => {
        if (!result || !result.data || !result.data.agentProfile) {
          return Promise.reject(new Error('No user found with agent profile'))
        }

        return result.data
      })
    }
  }

  return Promise.resolve(user)
}

function passwordResetRequest ({ email, phone }) {
  return apiRequests.put('/v1/agent-profile/password-reset-request/', { email, phone }).then(result => result.data)
}

function submitPasswordReset ({ verificationToken, password, confirmedPassword }) {
  return apiRequests.put('/v1/st-user/password-reset/', { verificationToken, password, confirmedPassword }).then(result => result.data)
}

export {
  renewToken,
  signOut,
  signIn,
  getSignedInUser,
  passwordResetRequest,
  submitPasswordReset
}
