<template>
  <div>
    <div v-if="!dateRange">
      <v-menu
        offset-y
        v-model="dateMenu"
        :close-on-content-click="parsedCloseOnContentClick"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            prepend-icon="fal fa-calendar"
            :value="computedDateFormatted"
            @click:clear="calendarDate = null"
            clearable
            readonly
            v-on="isEditable ? on : null"
            v-bind="textFieldProps"
          />
        </template>
        <v-date-picker
          no-title
          :min="computedDateMin"
          :max="computedDateMax"
          v-model="calendarDate"
          @change="dateMenu = false"
          v-bind="datePickerProps"
        />
      </v-menu>
    </div>
    <div v-if="dateRange" class="date-range-container">
      <div class="mr-md-3 mb-sm-3 mb-xs-3">
        <v-menu
          v-model="fromDateMenu"
          :close-on-content-click="parsedCloseOnContentClick"
          full-width
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              prepend-icon="fal fa-calendar"
              :value="computedDateFromFormatted"
              @click:clear="calendarDate.fromDate = null"
              clearable
              readonly
              v-on="isEditable ? on : null"
              v-bind="textFieldProps"
            />
          </template>
          <v-date-picker
            no-title
            :min="computedDateMin"
            v-model="calendarDate.fromDate"
            @change="fromDateMenu = false"
            v-bind="datePickerProps"
          />
        </v-menu>
      </div>
      <div>
        <v-menu
          v-model="toDateMenu"
          :close-on-content-click="parsedCloseOnContentClick"
          full-width
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              prepend-icon="fal fa-calendar"
              :value="computedDateToFormatted"
              @click:clear="calendarDate.toDate = null"
              clearable
              readonly
              v-on="isEditable ? on : null"
              v-bind="textFieldProps"
            />
          </template>
          <v-date-picker
            no-title
            :max="computedDateMax"
            v-model="calendarDate.toDate"
            @change="toDateMenu = false"
            v-bind="datePickerProps"
          />
        </v-menu>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.date-range-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>

<script>
import src from './src'
export default src
</script>
