import '@fortawesome/fontawesome-pro/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Resize } from 'vuetify/es5/directives'
// import 'vuetify/dist/vuetify.min.css'
import { THEME_COLORS } from '../utilities/helpers'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    ...THEME_COLORS,
    light: true
  },
  directives: {
    Resize
  },
  icons: {
    iconfont: 'fa',
    cancel: 'fal fa-ban',
    menu: 'fal fa-ellipsis-v-alt'
  }
})
