<template>
  <div>
    <div class="d-flex justify-center">
      <v-btn-toggle v-if="!hideVerificationOptions" class="mb-3" v-model="verificationType" dense mandatory rounded>
        <v-btn>By mobile number</v-btn>
        <v-btn>By Beacon/tag</v-btn>
      </v-btn-toggle>
    </div>
    <div v-if="verificationType === 0">
      <phone-number-field v-model="passengerMobile" :disabled="isSendingVerificationCode" :default-prefix="defaultPrefix" :child-props="{ label: 'Passenger mobile number' }" />
      <v-btn v-if="!disableVerificationOptions" block :disabled="!passengerMobile" :loading="isSendingVerificationCode" @click="sendVerificationCode()">Send verification code</v-btn>
    </div>
    <div v-if="verificationType === 1">
      <div v-if="!detectedQrCode" class="mb-3">
        <div class="text-center"><small>Let the camera see the qrcode on the passenger's ID tag</small></div>
        <st-qrcode-stream style="height: 250px" v-model="detectedQrCode" :filter-qr-code="filterQrCode" />
      </div>
      <div class="mb-3 text-center" v-show="detectedQrCode">
        <v-chip title="Beacon/tag">
          <v-icon left>far fa-signal-stream</v-icon> {{detectedQrCode}}
        </v-chip>
      </div>
      <v-btn block v-show="detectedQrCode" class="mb-3" :loading="isSendingVerificationCode" @click="resetQrcodeScan()">Scan and request again</v-btn>
    </div>
    <v-text-field v-if="!disableVerificationOptions" class="mt-10" v-model="verificationCode" label="Passenger verification code" placeholder="Ask passenger for code sent to their number" />
    <v-btn v-if="!disableVerificationOptions" block :disabled="!verificationCode" :loading="isVerifying" @click="confirmVerification()">Verify code</v-btn>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import src from './src'
export default src
</script>
