export default {
  props: {
    value: String,
    displayScannerFeedback: Boolean,
    filterQrCode: Function
  },
  data () {
    return {
      detectedQrCode: '',
      scannerLoading: false,
      isSendingVerificationCode: false,
      scannerErrorFeedback: '',
      scannerFeedback: ''
    }
  },
  mounted () {
  },
  watch: {
    detectedQrCode (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    async onScannerLoaded (scannerPromise) {
      try {
        this.scannerLoading = true
        await scannerPromise
        // successfully initialized
      } catch (error) {
        console.error(error)
        let message = ''

        if (error.name === 'NotAllowedError') {
          message = 'Camera permission was denied, enable permissions in your browser settings and reload the page: '
        } else if (error.name === 'NotFoundError') {
          message = 'No suitable camera device installed: '
        } else if (error.name === 'NotSupportedError') {
          message = 'Page is not served over HTTPS: '
        } else if (error.name === 'NotReadableError') {
          message = 'Camera might already be in use: '
        } else if (error.name === 'OverconstrainedError') {
          message = 'Front camera seems to have been requested, but none detected: '
        } else if (error.name === 'StreamApiNotSupportedError') {
          message = 'Browser seems to be lacking features: '
        }

        alert(message + error.message)
      } finally {
        this.scannerLoading = false
      }
    },
    onScannerDecode (decodedContent) {
      try {
        this.detectedQrCode = ''
        this.scannerErrorFeedback = ''

        if (typeof this.$props.filterQrCode === 'function') {
          this.detectedQrCode = this.$props.filterQrCode(decodedContent)
        }

        if (!this.detectedQrCode) {
          this.scannerFeedback = `Currently detected: ${decodedContent}`
        }
      } catch (error) {
        this.scannerErrorFeedback = error.message
      }
    },
    paintScannerBoundingBox (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 4
        ctx.strokeStyle = 'red'
        ctx.strokeRect(x, y, width, height)
      }
    },
  }
}
