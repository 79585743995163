import { getMoneyAccountTypes, maskPhoneNumber } from '@smarttransit/common-client'

import {
  getAgentMobileMoneyAccounts,
  registerAgentMobileMoneyAccount,
  removeAgentMobileMoneyAccount
} from '../../services/mobile-money-service'

import { addAlert } from '../../utilities/helpers'

export default {
  name: 'manage-mobile-money-accounts',
  props: {
    locale: String,
    latestAgentProfile: Object,
    defaultPrefix: String,
    onModalClose: Function
  },
  data () {
    return {
      mobileMoneyAccountDialog: false,
      currentMobileMoneyAccount: null,
      agentMobileMoneyAccounts: [],
      isRegisteringAccount: false,
      isDeletingAccount: false
    }
  },
  mounted () {
  },
  methods: {
    openModal () {
      this.mobileMoneyAccountDialog = true
      this.moneyAccountTypes = getMoneyAccountTypes()
      this.loadAgentMobileMoneyAccounts()
    },
    closeModal () {
      this.mobileMoneyAccountDialog = false

      if (this.onModalClose) {
        this.onModalClose()
      }
    },
    async registerCurrentMobileMoneyAccount () {
      if (!this.currentMobileMoneyAccount.phoneNumber) {
        return alert('Please provide a mobile number')
      }

      if (!this.currentMobileMoneyAccount.moneyAccountTypeId) {
        return alert('Please select a money account type')
      }

      try {
        this.isRegisteringAccount = true
        await registerAgentMobileMoneyAccount(this.currentMobileMoneyAccount)
        this.currentMobileMoneyAccount = null
        await this.loadAgentMobileMoneyAccounts()
      } catch (error) {
        console.error(error)
        addAlert({ message: error, type: 'error' })
      } finally {
        this.isRegisteringAccount = false
      }
    },
    async deleteCurrentMobileMoneyAccount (agentMobileMoneyAccount) {
      if (!confirm(`Confirm deleting account: ${agentMobileMoneyAccount.phoneNumberLabel} (${agentMobileMoneyAccount.moneyAccountTypeLabel})`)) {
        return
      }

      try {
        this.isDeletingAccount = true
        await removeAgentMobileMoneyAccount(agentMobileMoneyAccount.accountId)
        await this.loadAgentMobileMoneyAccounts()
      } catch (error) {
        console.error(error)
        addAlert({ message: error, type: 'error' })
      } finally {
        this.isDeletingAccount = false
      }
    },
    async createMobileMoneyAccountTemplate () {
      this.currentMobileMoneyAccount = {
        agentProfileId: this.latestAgentProfile.id,
        moneyAccountTypeId: null,
        phoneNumber: ''
      }
    },
    async loadAgentMobileMoneyAccounts () {
      try {
        const results = await getAgentMobileMoneyAccounts({ agentProfileId: this.latestAgentProfile.id })

        this.agentMobileMoneyAccounts = results.map((o) => {
          const moneyAccountType = this.moneyAccountTypes.find((m) => (m.value === o.moneyAccountTypeId))

          return {
            ...o,
            phoneNumberLabel: maskPhoneNumber(o.phoneNumber, this.$store.getters.phoneNumberCountryCodes),
            moneyAccountTypeLabel: moneyAccountType?.text,
            moneyAccountTypeImageSrc: moneyAccountType?.metadata?.src
          }
        })

        if (this.agentMobileMoneyAccounts.length === 1) {
          this.selectedMobileMoneyAccount = { ...this.agentMobileMoneyAccounts[0] }
        }
      } catch (error) {
        console.error(error)
        addAlert({ message: error, type: 'error' })
      }
    }
  }
}
