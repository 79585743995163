import { getMoneyAccountTypes } from '@smarttransit/common-client'

export default {
  props: {
    childProps: Object,
    placeholder: String,
    value: String // value obtained from: { value: 'mtnghana', text: 'MTN Mobile Money', metadata: { src: mtnghana } }
  },
  data () {
    return {
      mobileMoneyProvider: null,
      moneyAccountTypes: []
    }
  },
  computed: {
    currentPlaceholder () {
      return this.$props.placeholder || 'Select mobile money provider'
    }
  },
  mounted () {
    this.moneyAccountTypes = getMoneyAccountTypes()

    if (this.$props.value) {
      this.mobileMoneyProvider = this.moneyAccountTypes.find((o) => (o.value === this.$props.value))
    }
  },
  watch: {
    mobileMoneyProvider: function (newVal) {
      this.$emit('input', newVal && newVal.value)
    },
    value (val) {
      this.mobileMoneyProvider = this.moneyAccountTypes.find((o) => (o.value === val))
    }
  },
  methods: {
    clear () {
      this.mobileMoneyProvider = null
    }
  }
}
