import { getFromBrowserSetting, signIn, passwordResetRequest } from '../../services'
import { C_CLEAR_SITE_ALERTS, C_SET_BROWSER_SETTING } from '../../utilities/mutation-types'

function _login () {
  const dispatchParams = {}

  if (this.loginType === 'mobile') {
    dispatchParams.phoneNumber = this.mobile
  } else if (this.loginType === 'email') {
    dispatchParams.email = this.email
  }

  dispatchParams.password = this.password

  signIn(dispatchParams).then((result) => {
    this.$store.commit(C_CLEAR_SITE_ALERTS)

    if (this.afterLogin) {
      this.afterLogin(result)
    }
  }).catch(err => {
    this.dataErrors.push(err && err.error ? `${err.error.status}: ${err.error.message}` : JSON.stringify(err))

    if (this.onLoginError) {
      this.onLoginError(err)
    }
  })
}

function _resetForm () {
  this.email = ''
  this.mobile = ''
  this.password = ''
  this.dataErrors = []
}

function _sendForgotPasswordRequest () {
  const dispatchParams = {}

  if (this.loginType === 'mobile') {
    dispatchParams.phone = this.mobile
  } else if (this.loginType === 'email') {
    dispatchParams.email = this.email
  }

  passwordResetRequest(dispatchParams)
    .then(() => {
      this.forgotPasswordState = 'sent'
    })
    .catch((err) => {
      this.dataErrors.push(err && err.error ? `${err.error.status}: ${err.error.message}` : JSON.stringify(err))
    })
}

export default {
  name: 'sign-in-form',
  props: {
    beforeLogin: Function,
    afterLogin: Function,
    onLoginError: Function,
    onCancel: Function,
    errors: Array
  },
  data () {
    return {
      loginType: 'mobile',
      email: null,
      mobile: null,
      password: null,
      dataErrors: [],
      forgotPasswordState: false,
      viewPassword: false,
      passwordRules: [
        function (data) {
          if (!data || !data.trim()) {
            return 'Password is required'
          }
          return true
        }
      ],
      emailRules: [
        function (data) {
          if (!data || !data.trim()) {
            return 'Email is required'
          }
          return true
        },
        function (data) {
          if (!data || !/^.+@.+\..+$/.test(data)) {
            return 'Correct email format required'
          }
          return true
        }
      ]
    }
  },
  computed: {
    getFormErrors () {
      const _errors = this.errors || []
      return this.dataErrors ? this.dataErrors.concat(_errors) : _errors
    }
  },
  mounted: function () {
    const result = getFromBrowserSetting('signInLoginType')

    if (result) {
      this.loginType = result
    }
  },
  methods: {
    setLoginTypeBrowserSetting (loginType) {
      this.loginType = loginType
      this.$store.commit(C_SET_BROWSER_SETTING, { key: 'signInLoginType', value: loginType })
    },
    backToLogin () {
      this.dataErrors = []
      this.forgotPasswordState = false
    },
    cancelForgotPassword () {
      let cancelResult
      if (typeof this.onCancel === 'function') {
        cancelResult = this.onCancel()
      }
      if (cancelResult && cancelResult.then) {
        cancelResult.then(() => {
          this.dataErrors = []
          this.forgotPasswordState = false
        })
      } else {
        this.dataErrors = []
        this.forgotPasswordState = false
      }
    },
    cancel () {
      let cancelResult
      if (typeof this.onCancel === 'function') {
        cancelResult = this.onCancel()
      }
      if (cancelResult && cancelResult.then) {
        cancelResult.then(() => {
          _resetForm.bind(this)()
        })
      } else {
        _resetForm.bind(this)()
      }
    },
    loginForm (e) {
      this.dataErrors = []

      if (this.loginType === 'mobile') {
        if (!this.mobile || !this.mobile.trim()) {
          return 'Mobile number is required'
        }

        if (!this.forgotPasswordState) {
          if (!this.password || !this.password.trim()) {
            return 'Password is required'
          }
        }

        if (this.mobile.indexOf('+') !== 0) {
          return this.dataErrors.push('Mobile number country code is required')
        }
      } else if (this.loginType === 'email') {
        if (!this.email) {
          return
        }

        if (!/^.+@.+\..+$/.test(this.email)) {
          return this.dataErrors.push('Provide a valid email address')
        }
      }

      let possiblePromise

      if (this.beforeLogin) {
        possiblePromise = this.beforeLogin(e, { loginType: this.loginType, email: this.email, mobile: this.mobile, password: this.password })
      }
      if (possiblePromise && possiblePromise.then) {
        possiblePromise.then(() => {
          if (this.forgotPasswordState) {
            _sendForgotPasswordRequest.bind(this)()
          } else {
            _login.bind(this)()
          }
        })
      } else {
        if (this.forgotPasswordState) {
          _sendForgotPasswordRequest.bind(this)()
        } else {
          _login.bind(this)()
        }
      }

      return false
    }
  }
}
