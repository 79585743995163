import './styles/site-styles.scss'
import { getSignedInUser } from './services'
import { C_SET_SIGNED_IN_USER } from './utilities/mutation-types'

export default {
  name: 'App',
  data () {
    return {
      currentLocale: this.$route.params.locale || process.env.VUE_APP_DEFAULT_LOCALE,
      rootViewKey: Date.now(),
      pageHeightOffset: 100,
      menuItems: [
        { title: 'My Profile', icon: 'fa fa-user-edit', onClick: () => (this.$router.push({ name: 'profile', params: { locale: this.currentLocale } })) },
        // { title: 'My Transactions', icon: 'fa fa-book', onClick: () => (this.$router.push({ name: 'transactions', params: { locale: this.currentLocale } })) },
        // { title: 'Money Accounts', icon: 'fas fa-wallet', onClick: () => (this.$router.push({ name: 'money-accounts', params: { locale: this.currentLocale } })) },
        // { title: 'Change Password/PIN', icon: 'fas fa-key', onClick: () => (this.$router.push({ name: 'change-password', params: { locale: this.currentLocale } })) },
        { title: 'Sign Out', icon: 'fa fa-sign-out-alt', onClick: () => (this.$router.push({ name: 'signout', params: { locale: this.currentLocale } })) }
      ]
    }
  },
  computed: {
    signedInUser () {
      return this.$store.state.credentials.user || null
    },
    passengerAuthorizationToken () {
      if (this.signedInUser) {
        return this.$store.getters.passengerAuthorizationToken
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.onPageResize.bind(this)
    }, 800)

    if (this.$store.getters.token && this.$store.getters.token.expires > Date.now()) {
      getSignedInUser().then((user) => {
        if (user) {
          this.$store.commit(C_SET_SIGNED_IN_USER, { newUser: user })
        }
      }).catch(_ => {})
    }
  },
  methods: {
    onPageResize () {
      if (this.$refs && this.$refs.siteHeader && this.$refs.siteHeader.$el) {
        this.pageHeightOffset = (this.$refs.siteHeader.$el.clientHeight) //  + this.$refs.siteFooter.$el.clientHeight)
      }
    },
    forceRootViewRefresh () {
      this.$data.rootViewKey = Date.now()
    }
  },
  beforeDestroy () {
  }
}
