import _ from 'lodash'

import store from '../store'
import { C_ADD_SITE_ALERT } from './mutation-types'

function initBreadcrumbs () {
  const breadcrumbs = []
  if (_.get(this.$router, 'currentRoute.matched', []).length) {
    let foundCurrentRoute
    this.$router.currentRoute.matched.forEach((route, index) => {
      if (!foundCurrentRoute) {
        const routeObj = this.$router.resolve({ name: route.name, params: this.$router.currentRoute.params })
        foundCurrentRoute = (routeObj.route.name === this.$router.currentRoute.name)
        if (routeObj) {
          let accountName = index === 1 && this.$props.selectedAccount ? this.$props.selectedAccount.companyName : (routeObj.route.meta.title || routeObj.route.name)
          accountName = accountName.length > 30 ? accountName.substring(0, 30) + '...' : accountName
          breadcrumbs.push({
            text: accountName,
            disabled: foundCurrentRoute,
            to: { name: routeObj.route.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query },
            'active-class': foundCurrentRoute ? 'v-breadcrumbs__item--disabled' : ''
          })
        }
      }
    })
  }
  return breadcrumbs
}

const isAuthorized = function (user, role) {
  let isAuthorized
  if (user) {
    switch (role) {
      case 'superadmin':
        isAuthorized = _.intersectionBy(user.roles, [{ name: 'superadmin' }], 'name').length > 0
        break
      case 'admin':
        isAuthorized = _.intersectionBy(user.roles, [{ name: 'superadmin' }, { name: 'admin' }], 'name').length > 0
        break
      case 'agentstaff':
        isAuthorized = _.intersectionBy(user.roles, [{ name: 'superadmin' }, { name: 'admin' }, { name: 'agent' }], 'name').length > 0
        break
      case 'agent':
        isAuthorized = _.intersectionBy(user.roles, [{ name: 'superadmin' }, { name: 'admin' }, { name: 'agent' }, { name: 'agentstaff' }], 'name').length > 0
        break
      default:
        isAuthorized = _.some(user.roles, role)
    }
  }
  return isAuthorized
}

function addAlert ({ message, type, timeout, transient, id, isModal }) {
  const parsedMessage = (message && typeof message === 'object') ? (message.error && message.error.message ? message.error.message : (message.message ? message.message : JSON.stringify(message))) : message

  if (isModal) {
    alert(parsedMessage)
  } else {
    const parsedId = id || 'alert' + Date.now() + (Math.random() + '').split('.')[1]

    store.commit(C_ADD_SITE_ALERT, {
      id: parsedId,
      timeout,
      message: parsedMessage,
      type,
      transient: transient || type === 'success'
    })

    return parsedId
  }
}

function getDefaultPhonePrefix (user) {
  let countryCode

  if (user && user.agentProfile) {
    switch (user.agentProfile.countryId) {
      case 'GHA':
        countryCode = '+233'
        break
    }
  }

  if (user && !countryCode) {
    countryCode = '+1'
  }

  return countryCode
}

const THEME_COLORS = {
  primary: '#204da2',
  secondary: '#67be7b',
  accent: '#82B1FF',
  error: '#f65122',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107'
}

export {
  initBreadcrumbs,
  addAlert,
  isAuthorized,
  getDefaultPhonePrefix,
  THEME_COLORS
}
