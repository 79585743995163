import store from '../store'

const LOCAL_STORAGE_PREFIX = 'st_'
const cacheTimeoutHandles = {}
const userCacheTimeoutHandles = {}

function getFromCache (key) {
  const cacheKey = LOCAL_STORAGE_PREFIX + 'cache_' + key
  const value = localStorage.getItem(cacheKey)
  return (value ? (value.indexOf('[') === 0 || value.indexOf('{') === 0 ? JSON.parse(value) : value) : null)
}

function getFromUserCache (key) {
  const accessToken = store.getters.token
  if (accessToken) {
    const cacheKey = `${LOCAL_STORAGE_PREFIX}user_cache_${accessToken.userId}_${key}`
    const value = localStorage.getItem(cacheKey)
    return (value ? (value.indexOf('[') === 0 || value.indexOf('{') === 0 ? JSON.parse(value) : value) : null)
  } else {
    throw new Error(`No access token available to get user cache for key: ${key}`)
  }
}

function getFromBrowserSetting (key) {
  const storageKey = `${LOCAL_STORAGE_PREFIX}setting_${key}`
  if (store.state.browserSettings && store.state.browserSettings[key]) {
    return store.state.browserSettings[key]
  }

  const value = localStorage.getItem(storageKey)
  return (value ? (value.indexOf('[') === 0 || value.indexOf('{') === 0 ? JSON.parse(value) : value) : null)
}

function setToCache ({ key, value, expiryInSeconds }) {
  const cacheKey = LOCAL_STORAGE_PREFIX + 'cache_' + key
  _setCache({ key: cacheKey, value, expiryInSeconds })
  return true
}

function setToUserCache ({ key, value, expiryInSeconds }) {
  const accessToken = store.getters.token

  if (accessToken) {
    const cacheKey = `${LOCAL_STORAGE_PREFIX}user_cache_${accessToken.userId}_${key}`
    _setUserCache({ key: cacheKey, value, expiryInSeconds })
    return true
  } else {
    throw new Error(`No access token available to set user cache for key: ${key}`)
  }
}

function _setCache ({ key, value, expiryInSeconds }) {
  _set(key, cacheTimeoutHandles, { value, expiryInSeconds })
}

function _setUserCache ({ key, value, expiryInSeconds }) {
  _set(key, userCacheTimeoutHandles, { value, expiryInSeconds })
}

function _set (cacheKey, handle, { value, expiryInSeconds }) {
  expiryInSeconds = expiryInSeconds === undefined ? 60000 : expiryInSeconds // defaults to one minute

  if (expiryInSeconds) {
    handle[cacheKey] = setTimeout(() => {
      if (handle[cacheKey]) {
        clearTimeout(handle[cacheKey])
        handle[cacheKey] = undefined
      }

      let currentCache = localStorage.getItem(cacheKey)

      currentCache = currentCache ? (currentCache.indexOf('[') === 0 || currentCache.indexOf('{') === 0 ? JSON.parse(currentCache) : currentCache) : null

      if (currentCache) {
        localStorage.setItem(cacheKey, '')
      }

      localStorage.removeItem(cacheKey)
    }, expiryInSeconds * 1000)
  }

  localStorage.setItem(cacheKey, value && typeof value === 'object' ? JSON.stringify(value) : (value || ''))

  if (!value) {
    localStorage.removeItem(cacheKey)
  }
}

export {
  getFromCache,
  getFromUserCache,
  getFromBrowserSetting,
  setToCache,
  setToUserCache,
  LOCAL_STORAGE_PREFIX
}
