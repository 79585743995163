import _ from 'lodash'
import { C_SET_BROWSER_SETTING } from '../utilities/mutation-types'
import { LOCAL_STORAGE_PREFIX } from '../services'

export default {
  state: {
    browserSettings: {}
  },
  getters: {},
  mutations: {
    [C_SET_BROWSER_SETTING] (state, { key, value }) {
      const storageKey = LOCAL_STORAGE_PREFIX + 'setting_' + key
      const currentValue = localStorage.getItem(storageKey)
      if (_.isNil(value)) {
        if (state.browserSettings[key]) {
          delete state.browserSettings[key]
        }
        if (currentValue) {
          localStorage.setItem(storageKey, '')
          localStorage.removeItem(storageKey)
        }
      } else {
        state.browserSettings[key] = value
        localStorage.setItem(storageKey, value && typeof value === 'object' ? JSON.stringify(value) : (value || ''))
      }
    }
  }
}
